<template>
  <div class="pay_container">
    <el-row class="pay_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="pay_main">
      <p>就诊人：{{ patName }} ，卡号：{{ query.patCardNo }}</p>
      <p>
        <span class="red">您本次缴费金额为：</span>{{ query.ZJJE }}
        <span class="red">元</span>
      </p>
      <p>
        <span class="red">请在100秒内进行操作！</span>
      </p>
      <div class="sh">
        <div class="shm">
          <el-input style="height: 60px;" ref="scanInput" class="inpsm" v-model="handoverSeq" maxlength="18" v-focus @blur="inputblur()"></el-input>
        </div>
      </div>

      <p style="font-size:22px">
        <span class="red">如需取消支付请在手机完成支付之前点击屏幕【取消】按钮</span>
      </p>
      <p style="font-size:22px">
        <!-- <span class="red">扫码后请耐心等待系统打印缴费凭证，请勿点击屏幕取消按钮！</span> -->
      </p>
      <el-button type="primary" @click="cancel">取消</el-button>
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from './footer/index'
import { jsonPost, formPost } from '@/baseAPI'
import { payScanCode ,unLockReg,unLockAppointment} from '@/service/api'
import { outPatientForSet } from '@/service/outpatient'
import { lockRegToday, lockRegSave, lockRegTodaySave } from '@/service/register'
import Count from './count/index'
import dayjs from 'dayjs'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      patName: '',
      deptName: '',
      agtOrderNo:'',
      count: '',
      timer: '',
      timer1: '',
      query: {},
      handoverSeq: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.scanInput.focus()
    })
  },
  created() {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.query = this.$route.query
    // 获取扫码的值
    this.$nextTick(() => {
      this.$watch(
        'handoverSeq',
        this.debounce(newQuery => {
          if (this.handoverSeq.length === 18) {
            console.log('扫码获取的交接流水号', this.handoverSeq)
            this.payScanCode(this.handoverSeq)
          }
        }, 1000)
      )
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 微信二维码支付
    async payScanCode(authCode) {
      this.loading = true
      let data = {
        orderNum: this.query.HYID || this.$route.query.ouputh.SFID,
        visitType: '4',
        payMode: '1',
        payType: this.query.payType,
        payAmt: this.query.ZJJE,
        authCode,
        patCardNo: this.query.patCardNo,
        patCardType: this.query.patCardType,
        patName: this.query.patName,
        orderTime: new Date(),
        orderType: '9'
      }
      const res = await payScanCode(data)
      console.log(res)
      if (res.data.code == 0) {
        this.agtOrderNo = res.data.data
        this.loading = false
        if (this.query.payType == 1) {
          this.lockRegToday()
        } else if (this.query.payType == 2) {
          this.lockRegSave()
        } else {
          this.outPatientForSet()
        }
      } else if (res.data.code === 500 && res.data.msg === '运行时异常:null') {
        this.$message({
          message: res.data.msg,
          type: 'warning'
        })
        this.loading = false
      }
    },
    //当天挂号
    async lockRegToday() {
      let data = {
        KSBM: this.query.KSBM,
        YSBM: this.query.YSBM,
        ZJJE: this.query.ZJJE,
        CARDNO: this.query.patCardNo,
        CZRY: 'ZZJ001',
        FB: '1',
        HYID: this.query.HYID,
        FKFS: 'wxPay',
        YHJYLSH: this.agtOrderNo
      }
      let res = await lockRegTodaySave(data)
      console.log(res)
      if (res.data.data.SUC == 1) {
        this.$router.push({
          path: '/succeedOut',
          query: {
            XM: res.data.data.XM,
            CARDNO: res.data.data.CARDNO,
            FZDD: res.data.data.RESULT.FZDD,
            YSMC: res.data.data.YSMC,
            YJJJE: res.data.data.JE
          }
        })
      } else {
        this.tiS(res.data.data.MSG)
      }
    },
    //预约挂号保存
    async lockRegSave() {
      let data = {
        ZJJE: this.query.ZJJE,
        CARDNO: this.query.patCardNo,
        CZRY: 'ZZJ001',
        HYID: this.query.HYID,
        YHJYLSH: this.agtOrderNo
      }
      let res = await lockRegSave(data)
      console.log(res)

      if (res.data.data.SUC == 1) {
        this.$router.push({
          path: '/succeedOut',
          query: {
            XM: res.data.data.RESULT.XM,
            CARDNO: res.data.data.RESULT.CARDNO,
            TSXX: res.data.data.RESULT.TSXX,
            YSMC: res.data.data.RESULT.YCMC,
            YJJJE: res.data.data.RESULT.YJJJE,
            KSMC: res.data.data.RESULT.KSMC
          }
        })
      } else {
        this.tiS(res.data.data.MSG)
      }
    },
    // 门诊
    async outPatientForSet() {
      let data = {
        LSH: this.query.ouputh.LSH,
        MZH: this.query.ouputh.MZH,
        SFID: this.query.ouputh.SFID,
        CFRQ: this.query.ouputh.CFRQ,
        SBTCJE: this.query.ouputh.SBTCJE,
        SBZHJE: this.query.ouputh.SBZHJE,
        YHZFJE: this.query.ouputh.YHZFJE,
        JYRQ: dayjs(new Date()).format('YYYY-MM-DD'),
        JYSJ: this.query.ouputh.JYSJ,
        YLLB: this.query.ouputh.YLLB,
        FKFS: 'wxPay_QRCode'
      }
      let res = await outPatientForSet(data)
      console.log(res)
      if (res.data.data.SUC == 1) {
        this.$router.push({
          path: '/succeedOut',
          query: {
            XM: res.data.data.RESULT.XM,
            CARDNO: res.data.data.RESULT.MZH,
            INFO: res.data.data.RESULT.CommData2.INFO,
            YSMC: res.data.data.RESULT.JZYS,
            YJJJE: res.data.data.RESULT.FYZE,
            KSMC: res.data.data.RESULT.CommData2.KSMC
          }
        })
      } else {
        this.tiS(res.data.data.MSG)
      }
    }, // 初始获取焦点
    cancel() {
      let data ={
        YYID:this.query.YYID,
      }
      if (this.query.payType == 1) {
        unLockReg(data).then(()=>{
          this.$router.push('/home')
        })
        } else if (this.query.payType == 2) {
          unLockAppointment(data).then(()=>{
            this.$router.push('/home')
          })
        }
      this.$router.push('/home')
    },

    tiS(MSG) {
      this.$confirm(MSG, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$router.push('/home')
        })
        .catch(() => {
          this.$router.push('/home')
        })
    },
    keyPress() {
      // nextTik 针对DOM 没有渲染出现Undefined问题
      this.$nextTick(() => {
        this.$refs.scanInput.focus()
      })
    },
    // 失去焦点
    inputblur() {
      let that = this
      // FireFox 和 IE 失去焦点，blur直接执行focus 不会生效，加个延时
      setTimeout(() => {
        that.$refs.scanInput.focus()
      }, 10)
    },
    //防抖处理
    debounce(func, delay) {
      let timer
      return function(...args) {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          func.apply(this, args)
        }, delay)
      }
    },
    countdown() {
      const TIME_COUNT = 100
      this.count = TIME_COUNT
      this.timer1 = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
          }
        } else {
          clearInterval(this.timer1)
          this.timer1 = null
        }
      }, 1000)
    }
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timer1)
  }
}
</script>
<style lang="less">
.pay_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.pay_main {
  text-align: center;
}

.pay_main .count {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 34px;
}

.pay_main p {
  color: #2259a1;
  margin-top: 30px;
  font-size: 50px;
}

.pay_main .red {
  color: #dc3545;
  font-size: 45px;
  font-weight: 700;
}

.pay_main img {
  /* width: 400px;
  height: 400px; */
}

.pay_main .el-button {
  font-size: 40px;
  width: 220px;
  height: 130px;
}

.pay_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
.sh {
  width: 100%;
  height: 11rem;
  display: flex;
  justify-content: center;
  .shm {
    width: 60%;
    background-image: url('../assets/shi.png');
    background-position: center center;
    background-size: cover;
    background-color: #464646;
    z-index: 999;
  }
}


</style>
